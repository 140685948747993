// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "LocationBrochureEditForm__buttonCreateContainer__fQyPD";
export var column = "LocationBrochureEditForm__column__DCJCb";
export var documentRow = "LocationBrochureEditForm__documentRow__dgVSi";
export var editor = "LocationBrochureEditForm__editor__iXmZE";
export var fileNameText = "LocationBrochureEditForm__fileNameText__IDT1A";
export var fileSizeText = "LocationBrochureEditForm__fileSizeText__kLhyb";
export var flexContainer = "LocationBrochureEditForm__flexContainer__mTGyn";
export var grid = "LocationBrochureEditForm__grid__MfyWg";
export var iconDelete = "LocationBrochureEditForm__iconDelete___qLAu";
export var iconView = "LocationBrochureEditForm__iconView__ub_TM";
export var labelContainer = "LocationBrochureEditForm__labelContainer__Wlxi4";
export var publishedAtPicker = "LocationBrochureEditForm__publishedAtPicker__bHeim";
export var row = "LocationBrochureEditForm__row__EFVFo";
export var uploadDocumentButton = "LocationBrochureEditForm__uploadDocumentButton__zBRkH";